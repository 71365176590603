import { useEffect, useState, useCallback } from 'react'
import logo from '../../../media/LogoOzel.png'
import { API } from '../../../config'
import axios from 'axios'
import popUp from '../../../components/popUp'
import './code.css'

const Code = ({ setState, trivia, getTrivia, setUser, history, setAnswered, setCurrentQuestion }) => {
  const [code, setCode] = useState('')
  const [userForm, setUserForm] = useState({ name: '', email: '', checkbox: false })
  const [urlData, setUrlData] = useState({
    name: "",
    email: "",
    checkbox: false
  })

  useEffect(() => {

    if (window.location.search !== "") {
      let queryData = decodeURIComponent(window.location.search).split('?')[1].split('&&')
      let userData = {}
      queryData.map(ele => {
        userData[ele.split('=')[0]] = ele.split('=')[1]
      })
      setUrlData({
        ...urlData,
        email: userData.email,
        name: userData.name,
        logo: userData.logo,
        checkbox: true
      })
      setUserForm({
        ...userForm,
        email: userData.email,
        name: userData.name,
        checkbox: true
      })
    }

  }, [])

  const addUser = useCallback(async (e) => {
    e.preventDefault()

    if ((e && e.key !== 'Enter') && (e && e.type !== 'click')) return

    if (!userForm.name || !userForm.email || !code) {
      popUp('Faltan campos por llenar', 'warning')
      return
    }
    if (!userForm.checkbox) {
      popUp('Aún no aceptas terminos y condiciones', 'warning')
      return
    }
    const triviaRes = await getTrivia(code)
    if (triviaRes) {
      let options = {
        data: { email: userForm.email.trim().toLowerCase() },
        method: 'POST',
        url: `${API}/player/trivia/${code}/`,
        headers: { "Content-Type": "application/json" }
      }
      try {
        const res = await axios.request(options)
        if (res.data !== '') {
          setUser(res.data.user)
          if (triviaRes.state === 'finished') {
            setState(3)
            return
          }
          let currentQuestion = 0
          for (let i = 0; i < triviaRes.question.length; i++) {
            const element = triviaRes.question[i];
            if (element._id === res.data.question._id) {
              if (triviaRes.question.length === i + 1) {
                setCurrentQuestion(i)
                setAnswered(0)
                setState(3)
                return
              } else {
                setCurrentQuestion(i)
              }
              currentQuestion = i
              break
            }
          }
          if (res.data.choice) {
            for (let i = 0; i < triviaRes.question[currentQuestion].choice.length; i++) {
              const element = triviaRes.question[currentQuestion].choice[i];
              if (element._id === res.data.choice._id) {
                setAnswered(i + 1)
                break
              }
            }
          } else {
            setAnswered(0)
          }
          setState(4)
          return
        }
        /* setUser(res.data)
         */
      } catch (error) {
        console.error(error);
      }
      options = {
        data: { name: userForm.name.trim(), email: userForm.email.trim().toLowerCase() },
        method: 'POST',
        url: API + "/register/",
        headers: { "Content-Type": "application/json" }
      }
      try {
        const res = await axios.request(options)
        setUser(res.data)
        setState(1)
      } catch (error) {
        console.error(error);
      }
    } else {
      popUp('Trivia no encontrada', false)
    }
  }, [userForm, setState, setUser, code, getTrivia, setAnswered, setCurrentQuestion])

  useEffect(() => {
    window.addEventListener('keyup', addUser, true)
    return () => {
      window.removeEventListener('keyup', addUser, true)
    }
  }, [addUser])

  return (
    <div className={`CodeContainer linearGradientBackgound1 ${urlData.name && urlData.email ? "embebed" : ""}`}>
      <header className='CodeHeader'>
        {
          urlData.name === "" && urlData.email === "" &&
          <img src={logo} alt='Logo de Ozel' /* onClick={() => history.push('/')} *//>
        }
      </header>
      <div className="CodeContent">
        {/* <h1 style={{ color: "#FFF9" }}>Ingreso a Trivia</h1> */}
        <form className='CodeFormContainer'>
          <h2 style={{ color: "#FFF9" }}>Trivia</h2>
          <div className={`CodeForm whiteBackground ${urlData.email ? "" : 'purpleColor'}`}>
            {
              urlData.name === "" && urlData.email === "" &&
              <div className='CodeFormInput'>
                <label htmlFor='name'>Nombre</label>
                <input type="text" id='name' className='purpleColor' name='name' value={userForm.name} onChange={e => setUserForm({ ...userForm, name: e.target.value })} />
              </div>
            }
            {
              urlData.name === "" && urlData.email === "" &&
              <div className='CodeFormInput'>
                <label htmlFor='email'>Correo electrónico</label>
                <input type="email" id='email' className='purpleColor' name='email' value={userForm.email} onChange={e => setUserForm({ ...userForm, email: e.target.value })} />
              </div>
            }
            <div className='CodeFormInput'>
              <label htmlFor='code'>Código</label>
              <input
                type="number"
                id='code'
                className={`${urlData.email ? "" : 'purpleColor'}`}
                name='code'
                value={code}
                onChange={e => {
                  setUrlData({ ...urlData, code: e.target.value.trim() })
                  setCode(e.target.value.trim())}
                }
              />
            </div>
          </div>
          {
            urlData.name === "" && urlData.email === "" &&
            <div className='RegisterFormCheckbox whiteBackground purpleColor'>
              <input type="checkbox" name='checkbox' checked={userForm.checkbox} onChange={e => setUserForm({ ...userForm, checkbox: e.target.checked })} />
              <label htmlFor='checkbox'>Acepto la Política de Privacidad. Para ver más información haz clic <a href="https://www.roche.com.co/es/politica-new.html" target="_blank" rel="noopener noreferrer" style={{ color: "#3e1e63", textDecoration: "none" }}>aqui.</a></label>
            </div>
          }
          <button className={`CodeOptionButton whiteBackground ${urlData.email ? "" : 'purpleColor'} semiBold`} onClick={addUser}>INGRESAR</button>
        </form>
        {
          urlData.name === "" && urlData.email === "" &&
          <button className='registerCodeOption semiBold' onClick={() => history.push('/trivia/register')}>CLIC AQUÍ PARA CREAR TRIVIAS</button>
        }
        <img src={urlData.logo ? urlData.logo : logo} alt="" className='CodeFooterImg' />
      </div>
    </div>
  )

}

export default Code
