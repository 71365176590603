/* ====== Imagen Juego 1 ====== */
import Ficha1_D1 from './day1/Ficha_1.png'
import Ficha2_D1 from './day1/Ficha_2.png'
import Ficha3_D1 from './day1/Ficha_3.png'
import Ficha4_D1 from './day1/Ficha_4.png'
import Ficha5_D1 from './day1/Ficha_5.png'
import Ficha6_D1 from './day1/Ficha_6.png'
import Ficha7_D1 from './day1/Ficha_7.png'
import Ficha8_D1 from './day1/Ficha_8.png'
import Portada_1 from './day1/portD1.png'

/* ====== Imagen Juego 2 ====== */
import Ficha1_D2 from './day2/Ficha_1.png'
import Ficha2_D2 from './day2/Ficha_2.png'
import Ficha3_D2 from './day2/Ficha_3.png'
import Ficha4_D2 from './day2/Ficha_4.png'
import Ficha5_D2 from './day2/Ficha_5.png'
import Ficha6_D2 from './day2/Ficha_6.png'
import Ficha7_D2 from './day2/Ficha_7.png'
import Ficha8_D2 from './day2/Ficha_8.png'
import Portada_2 from './day2/portD2.png'

/* ====== Imagen Juego 3 ====== */
import Ficha1_D3 from './day3/Ficha_1.png'
import Ficha2_D3 from './day3/Ficha_2.png'
import Ficha3_D3 from './day3/Ficha_3.png'
import Ficha4_D3 from './day3/Ficha_4.png'
import Ficha5_D3 from './day3/Ficha_5.png'
import Ficha6_D3 from './day3/Ficha_6.png'
import Ficha7_D3 from './day3/Ficha_7.png'
import Ficha8_D3 from './day3/Ficha_8.png'
import Portada_3 from './day3/portD3.png'

/* ====== Imagen Juego 4 ====== */
import Ficha1_D4 from './day4/Ficha_1.png'
import Ficha2_D4 from './day4/Ficha_2.png'
import Ficha3_D4 from './day4/Ficha_3.png'
import Ficha4_D4 from './day4/Ficha_4.png'
import Ficha5_D4 from './day4/Ficha_5.png'
import Ficha6_D4 from './day4/Ficha_6.png'
import Ficha7_D4 from './day4/Ficha_7.png'
import Ficha8_D4 from './day4/Ficha_8.png'
import Portada_4 from './day4/portD4.png'


export const imagesD1 = {
  Ficha1_D1,
  Ficha2_D1,
  Ficha3_D1,
  Ficha4_D1,
  Ficha5_D1,
  Ficha6_D1,
  Ficha7_D1,
  Ficha8_D1,
  Portada_1,
}

export const imagesD2 = {
  Ficha1_D2,
  Ficha2_D2,
  Ficha3_D2,
  Ficha4_D2,
  Ficha5_D2,
  Ficha6_D2,
  Ficha7_D2,
  Ficha8_D2,
  Portada_2,
}

export const imagesD3 = {
  Ficha1_D3,
  Ficha2_D3,
  Ficha3_D3,
  Ficha4_D3,
  Ficha5_D3,
  Ficha6_D3,
  Ficha7_D3,
  Ficha8_D3,
  Portada_3,
}

export const imagesD4 = {
  Ficha1_D4,
  Ficha2_D4,
  Ficha3_D4,
  Ficha4_D4,
  Ficha5_D4,
  Ficha6_D4,
  Ficha7_D4,
  Ficha8_D4,
  Portada_4,
}
