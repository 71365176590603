import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import logo from '../../media/LogoOzel2.png'
import SignOff from '../../components/signOff';
import './triviaDashBoard.css'
import { API } from '../../config'
import Cookies from 'universal-cookie';


const cookies = new Cookies();

let TriviaDashBoard = ({ history }) => {
  const [admin, setAdmin] = useState(null)

  useEffect(() => {
    const getAdmin = async () => {
      try {
        const auxAdmin = await cookies.get('admin')
        if (!auxAdmin || auxAdmin === 'null') {
          history.push('/trivia/login')
          return
        }
        setAdmin(auxAdmin)
      } catch (error) {
        console.error(error);
      }
    }
    if (!admin) {
      getAdmin()
    }
  }, [admin, history])

  return (
    <div className="DashBoardContainer">
      <header className='DashBoardHeader'>
        {admin ?
          <img src={`${API}/register-admin/logo/${admin._id}.png`} alt="Logo" onError={(e) => e.target.src = logo} />
          : <div></div>}
        <div className='DashBoardHeaderRight'>
          <SignOff history={history} />
        </div>
      </header>
      <main className='DashBoardContent'>
        <button className='DashBoardButton whiteColor purpleBackground semiBold' onClick={() => history.push('/trivia/dashboard/triviaedit/')}>CREAR TRIVIA</button>
        <button className='DashBoardButton whiteColor purpleBackground semiBold' onClick={() => history.push('/trivia/dashboard/available')}>TRIVIAS DISPONIBLES</button>
      </main>
      <footer className='DashBoardFooter'>
        <img src={logo} alt="Logo Ozel Producciones" />
      </footer>
    </div>
  )
}

export default withRouter(TriviaDashBoard)
