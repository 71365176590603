import React from 'react'
import logo from '../../media/LogoOzel.png'
import clock from '../../media/clock.png'
import '../../pages/triviaOnline/triviaOnline.css'
import '../../pages/manageTrivia/ranking/ranking.css'
import { API } from '../../config'

const Preview = ({trivia, currentQuestion, setPreview}) => {
  const answered = true
  return (
    <div className='OnlineContainer linearGradientBackgound1'>
      <button className='purpleColor semiBold whiteBackground RankingBackButton' onClick={() => setPreview(false)}>VOLVER</button>
      {
        trivia && currentQuestion !== null ?
          <React.Fragment>
            <img src={`${API}/register-admin/logo/${trivia.admin._id}.png`} alt="Logo" className='OnlineImgHeader' onError={(e) => e.target.src = logo} />
            <div className='OnlineContent'>
              <div className='OnlineTimeLeft'>
                <img src={clock} alt="" />
                <div className='OnlineTimeLeftText whiteColor'>
                  <p>Te quedan:</p>
                  <h3>{trivia.question[currentQuestion].timeout / 1000 } segundos</h3>
                </div>
              </div>
              <div className='OnlineIframe' dangerouslySetInnerHTML={{ __html: trivia.question[currentQuestion].mediaUrl }}></div>
              <div className='OnlineQuestion'>
                <h1>{trivia.question[currentQuestion].text}</h1>
              </div>
              <div className='OnlineAnswers'>
                {trivia.question[currentQuestion].choice.map((choice, i) => (
                  <div className={answered !== null && choice.right ? 'OnlineCorrectAnswer' : answered !== null && answered === i + 1 ? 'OnlineWrongAnswer' : 'OnlineAnswer'} key={choice._id} >
                    <h4>{choice.text}</h4>
                  </div>
                ))}
              </div>
            </div>
            <img src={logo} alt="" className='OnlineImgFooter' />
          </React.Fragment>
          : ''
      }
    </div>
  )
}

export default Preview