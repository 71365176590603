import React from 'react';
import Cookies from 'universal-cookie';
import LogOut from '../media/logOut.png'
import ProfileLogo from '../media/ProfileLogo.png'
import '../pages/triviaDashboard/triviaDashBoard.css'

const cookies = new Cookies();

const SignOff = ({ history }) => {
  const off = () => {
    cookies.set('admin', 'null', {path:'/'})
    cookies.set('jwt', 'null', {path:'/'})
    history.push('/trivia/login')
  }
  return (
    <React.Fragment>
      <div className='DashBoardHeaderRightItem' onClick={() => history.push('/trivia/profile/')}>
        <img src={ProfileLogo} alt="Perfil" id='profile' />
        <label htmlFor="#profile" className='purpleColor semiBold'>Mi perfil</label>
      </div>
      <div className='DashBoardHeaderRightItem' onClick={off}>
        <img src={LogOut} alt="Perfil" id='profile' />
        <label htmlFor="#profile" className='purpleColor semiBold'>Cerrar sesion</label>
      </div>
    </React.Fragment>
  )
}

export default SignOff