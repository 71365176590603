import { useEffect, useState } from 'react'
import { withRouter }  from 'react-router-dom'
import Cookies from 'universal-cookie'
import logo from '../../media/LogoOzel.png'

import './gameLobby.css'

const Welcome = (props) => {
  const cookies = new Cookies();
  const { history } = props
  const [urlData, setUrlData] = useState({
    name: "",
    email: "",
    checkbox: false
  })

  useEffect(() => {
    let urlDataC = cookies.get('playedGamer')
    setUrlData(urlDataC)

    console.log(urlDataC);
    return () => { /* Unmount */ }
  }, [])

  return (
    <div className='WelcomeContainer linearGradientBackgound1'>
      <div className='WelcomeContent'>
        <h1 className='whiteColor'>BIENVENIDOS</h1>
        <p className='whiteColor'>Recuerda que solo tienes una oportunidad para responder, así que debes tener en cuenta lo siguiente:</p><br />
        <p className='whiteColor'>1. Antes de iniciar verifica tener una buena conexión a internet.</p>
        <p className='whiteColor'>2. No salir de la plataforma mientras estás participando en ella.</p>
        <p className='whiteColor'>3. Recuerda que tienes un tiempo límite, debes estar pendiente de tu reloj.</p>
        <button className='WelcomeButton semiBold' onClick={e => {history.push('/memorygame/game')}}>EMPEZAR</button>
      </div>
      <img src={urlData.logo ? urlData.logo : logo} alt="" className='WelcomeImgFooter' />
    </div>
  )
}

export default withRouter(Welcome)
