import { useEffect, useState } from 'react'
import axios from 'axios'
import logo from '../../../media/LogoOzel.png'
import { API } from '../../../config'
import place1 from '../../../media/place1.png'
import place2 from '../../../media/place2.png'
import place3 from '../../../media/place3.png'
import './score.css'

const Score = ({ trivia, user, history }) => {
  const [score, setScore] = useState([])
  const [myPosition, setMyPosition] = useState({ number: '', score: '' })

  useEffect(() => {
    const calculate = async () => {
      if (trivia) {
        try {
          const res = await axios.get(API + "/trivia/score/total/" + trivia._id)
          console.log(res);
          let scoreList = []
          for (const key in res.data) {
            scoreList.push({ name: res.data[key].user.name, score: res.data[key].score, id: key })
          }
          const newList = scoreList.sort((a, b) => {
            if (a.score > b.score) {
              return -1
            } else {
              return 1
            }
          })
          newList.forEach((item, i) => {
            if (user && item.id === user._id) {
              setMyPosition({number: i + 1, score: item.score})
              return
            }
          });

          setScore(newList)
        } catch (error) {
          console.error(error);
        }
      }
    }
    calculate()
  }, [trivia, user])

  if (trivia.state !== 'finished') {
    return (
      <div className='WelcomeContainer linearGradientBackgound1'>
        <div className='WelcomeContent'>
          <h1 className='whiteColor'>Gracias por participar.</h1>
        </div>
        <img src={logo} alt="" className='WelcomeImgFooter' />
      </div>
    )
  }
  return (
    <div className='ScoreContainer linearGradientBackgound1'>
      <div className='ScoreContent'>
        <div>
          <h2 className='whiteColor noBold'>QUEDASTE EN LA POSICIÓN #{myPosition.number}</h2>
          <h2 className='whiteColor noBold'>TU PUNTAJE ES {myPosition.score}</h2>
          <h2 className='whiteColor semiBold'>¡FELICITACIONES!</h2>
        </div>
        <div className='ScorePlaces'>
          <div className='ScorePlace secondPlace'>
            <img src={place2} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor semiBold'>{score[1] ? score[1].name : ''}</h4>
              <h4 className='whiteColor noBold'>{score[1] ? score[1].score : ''}</h4>
            </div>
          </div>
          <div className='ScorePlace firstPlace'>
            <img src={place1} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor semiBold'>{score[0] ? score[0].name : ''}</h4>
              <h4 className='whiteColor noBold'>{score[0] ? score[0].score : ''}</h4>
            </div>
          </div>
          <div className='ScorePlace thirdPlace'>
            <img src={place3} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor semiBold'>{score[2] ? score[2].name : ''}</h4>
              <h4 className='whiteColor noBold'>{score[2] ? score[2].score : ''}</h4>
            </div>
          </div>
        </div>
        <button className='purpleColor semiBold whiteBackground ScoreBackButton' onClick={() => { window.location.reload() }}>VOLVER</button>
      </div>
      <img src={logo} alt="" className='ScoreImgFooter' />
    </div>
  )
}

export default Score