import React from 'react'
import logo from '../../media/LogoOzel.png'
import './score.css'

const Ends = () => {
  return (
    <div className='WelcomeContainer linearGradientBackgound1'>
      <div className='WelcomeContent'>
        <h1 className='whiteColor'>Gracias por participar.</h1>
      </div>
      <img src={logo} alt="" className='WelcomeImgFooter' />
    </div>
  )
}

export default Ends