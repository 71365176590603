import logo from '../../media/LogoOzel.png'
import { withRouter } from 'react-router-dom'
import './home.css'

const Home = ({ history }) => {
  return (
    <div className="HomeContainer linearGradientBackgound1 whiteColor">
      <div className="gamesCont">
        <button className='whiteColor' onClick={() => history.push('/trivia/triviaonline')}>INGRESAR A UNA TRIVIA</button>
        <button className='whiteColor' onClick={() => history.push('/memorygame/login')}>INGRESAR A UN CONCÉNTRESE</button>
      </div>
      <img src={logo} alt="" />
      <button className='whiteColor' onClick={() => history.push('/trivia/login')}>CREAR UNA TRIVIA</button>
    </div>
  )
}

export default withRouter(Home)
