import Swal from 'sweetalert2'

/**
 * @msg Message in the popup
 * @type Type of alert (true, false, "warning")
 * @position Alert position (Ejm: "bottom"), default("bottom-end")
 * @time Alert time in milliseconds (Ejm: 5000), default(3000)
 */


export default function success(msg, type, position, time) {
  const Toast = Swal.mixin({
    toast: true,
    position: position ? position : 'bottom-end',
    showConfirmButton: false,
    timer: time ? time : 3000,/* 
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    } */
  })

  if (type) {
    if (type === 'warning') {
      Toast.fire({
        icon: 'warning',
        title: msg
      })
    } else {
      Toast.fire({
        icon: 'success',
        title: msg
      })
    }
  } else {
    Toast.fire({
      icon: 'error',
      title: msg
    })
  }
}