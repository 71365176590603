import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import popUp from '../../components/popUp'
import Code from './code/code'
import Welcome from './welcome/welcome'
import FeedBack from './feedBack/feedBack'
import Score from './score/score'
import logo from '../../media/LogoOzel.png'
import clock from '../../media/clock.png'
import { API, ws } from '../../config'
import io from 'socket.io-client'

import './triviaOnline.css'

let socket = null
const TriviaOnline = (props) => {
  const [state, setState] = useState(0)
  const [trivia, setTrivia] = useState(null)
  const [user, setUser] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [answered, setAnswered] = useState(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [connected, setConnected] = useState(false)
  const [blocked, setBlocked] = useState(false)

  const getTrivia = useCallback(async (code) => {
    try {
      const res = await axios.get(API + '/trivia/code/' + code)
      setTrivia(res.data)
      return res.data
    } catch (error) {
      console.error(error);
      return false
    }
  },// eslint-disable-next-line
    [])

  useEffect(() => {
    if (!trivia && props && props.match && props.match.params && props.match.params.triviaID) {
      getTrivia(props.match.params.triviaID)
    }
    if (trivia && user && !socket) {
      socket = io.connect(ws, { transports: ['websocket'] })
      socket.emit('joinTrivia', { code: trivia.code, userId: user._id, name: user.name })
    }
    if (socket) {
      socket.on('conected', () => {
        setConnected(true)
      })
      socket.on('leave', () => {
        popUp('Usuario esta conectado', 'warning')
        setConnected(false)
        setState(0)
        socket.disconnect()
        setTimeout(() => {
          window.location.reload()
        }, 3000);
      })

      socket.on('changeState', ({ state }) => {
        setTrivia({ ...trivia, state: state })
      })
    }

    return () => {
      if (socket) {
        socket.off()
      }
    }// eslint-disable-next-line
  }, [trivia, user])

  const addAnswer = useCallback(
    async (choice, i) => {
      let data = null
      if (choice) data = { user: user._id, time: currentTime, trivia: trivia._id, question: trivia.question[currentQuestion]._id, choice: choice._id }
      else data = { user: user._id, time: currentTime, trivia: trivia._id, question: trivia.question[currentQuestion]._id }
      if (answered === null) {
        const options = {
          data: data,
          method: 'POST',
          url: API + "/player/",
          headers: { "Content-Type": "application/json" }
        }
        console.log(options);
        try {
          const res = await axios.request(options)
          console.log(res);
        } catch (error) {
          console.error(error);
        }
        setAnswered(i + 1)

      }
    },
    [answered, currentQuestion, currentTime, trivia, user],
  )

  useEffect(() => {
    let time = null
    let time2 = null
    let auxCurrentTime = currentTime
    if (trivia && currentQuestion !== null && trivia.question[currentQuestion].timeToThink > 0 && answered === null) {
      let auxTime = trivia.question[currentQuestion].timeToThink
      const interval = setInterval(() => {
        let auxTrivia = { ...trivia }
        auxTime -= 1000
        auxTrivia.question[currentQuestion].timeToThink = auxTime
        setTrivia(auxTrivia)
        if (auxTime <= 0) {
          clearInterval(interval)
        }
      }, 1000);
      time2 = setTimeout(() => {
        let auxTrivia0 = { ...trivia }
        auxTrivia0.question[currentQuestion].timeToThink = 0
        setTrivia(auxTrivia0)
      }, trivia.question[currentQuestion].timeToThink);
    } else if (trivia && currentQuestion !== null && trivia.question[currentQuestion].timeout > 0 && answered === null) {
      let auxTime = trivia.question[currentQuestion].timeout
      time = setInterval(() => {
        let auxTrivia = { ...trivia }
        auxTime -= 1000
        auxTrivia.question[currentQuestion].timeout = auxTime
        auxCurrentTime += 1000
        setTrivia(auxTrivia)
        setCurrentTime(auxCurrentTime)
        if (auxTime <= 0) {
          addAnswer(null, -1)
          clearInterval(time)
        }
      }, 1000);
    }
    return () => {
      if (time) {
        clearInterval(time)
      }
      if (time2) {
        clearTimeout(time2)
      }
    }// eslint-disable-next-line
  }, [state, answered, currentQuestion, addAnswer])

  const nextQuestion = () => {
    if (trivia.question.length > currentQuestion + 1) {
      setBlocked(false)
      setCurrentQuestion(currentQuestion + 1)
      setAnswered(null)
      setCurrentTime(0)
    } else {
      setState(2)
      setBlocked(false)
    }
  }

  switch (state) {
    case 0:
      return (
        <Code answered={answered} setState={setState} trivia={trivia} getTrivia={getTrivia} setUser={setUser} history={props.history} setAnswered={setAnswered} setCurrentQuestion={setCurrentQuestion} />
      )
    case 1:
      return (
        <Welcome setState={setState} setCurrentQuestion={setCurrentQuestion} trivia={trivia} />
      )
    case 2:
      return (
        <FeedBack user={user} trivia={trivia} setState={setState} />
      )
    case 3:
      return (
        <Score user={user} trivia={trivia} history={props.history} />
      )
    default:
      return (
        <div className='OnlineContainer linearGradientBackgound1'>
          {
            trivia && currentQuestion !== null && connected ?
              <React.Fragment>
                <img src={`${API}/register-admin/logo/${trivia.admin._id}.png`} alt="Logo" className='OnlineImgHeader' onError={(e) => e.target.src = logo} />
                <div className='OnlineContent'>
                  <div className='OnlineTimeLeft'>
                    <img src={clock} alt="" />
                    <div className='OnlineTimeLeftText whiteColor'>
                      <p>Te quedan:</p>
                      <h3>{trivia.question[currentQuestion].timeout / 1000} segundos</h3>
                    </div>
                  </div>
                  <div className='OnlineIframe' dangerouslySetInnerHTML={{ __html: trivia.question[currentQuestion].mediaUrl }}></div>
                  <div className='OnlineQuestion'>
                    <h1>{trivia.question[currentQuestion].text}</h1>
                  </div>
                  <div className='OnlineAnswers'>
                    {
                      trivia.question[currentQuestion].timeToThink <= 0 
                      ? trivia.question[currentQuestion].choice.map((choice, i) => {

                          return (
                            <div
                              className={answered !== null && choice.right ? 'OnlineCorrectAnswer' : answered !== null && answered === i + 1 ? 'OnlineWrongAnswer' : 'OnlineAnswer'}
                              key={choice._id}
                              onClick={() => {
                                if (!blocked) {
                                  console.log("CLICK");
                                  addAnswer(choice, i)
                                }
                                setBlocked(true)
                              }}
                              id={blocked ? "greyAnswer" : "clearAnswer"}
                            >
                              <h4>{choice.text}</h4>
                              {answered !== null && answered === i + 1 ? choice.right ?
                                <div className='OnlineCheck'>&#10004;</div>
                                : <div className='OnlineCheck'>X</div> : ''}
                            </div>
                          )
                        })
                      :
                        <div className='timeToThinkContainer'>
                          <h2 className='semiBold whiteColor timeToThink'>{trivia.question[currentQuestion].timeToThink / 1000}</h2>
                        </div>
                    }
                  </div>
                  {answered !== null ?
                    <button className='OnlineButton semiBold' onClick={nextQuestion}>Continuar</button>
                    : ''
                  }
                </div>
                <img src={logo} alt="" className='OnlineImgFooter' />
              </React.Fragment>
              : ''
          }
        </div>
      )
  }

}


export default withRouter(TriviaOnline)
