import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import ProfileLogo from '../../media/ProfileLogo.png'
import Cookies from 'universal-cookie';
import logo from '../../media/LogoOzel2.png'
import profileUpdate from '../../media/profileUpdate.png'
import profileData from '../../media/profileData.png'
import profileStatistics from '../../media/profileStatistics.png'
import { API } from '../../config'
import './profile.css'
import popUp from '../../components/popUp'

const cookies = new Cookies();

const Profile = ({ history }) => {
  const [dataProfile, setDataProfile] = useState(false)
  const [admin, setAdmin] = useState(null)

  useEffect(() => {
    const getAdmin = async () => {
      const auxAdmin = await cookies.get('admin')
      if (!auxAdmin || auxAdmin === 'null') {
        history.push('/trivia/login')
        return
      }
      setAdmin(auxAdmin)
    }
    if (!admin) {
      getAdmin()
    }
  }, [history, admin])

  const uploadLogo = async(e) => {
    console.log(e.target.files[0]);
    const newLogo = new FormData()
    newLogo.append('logo', e.target.files[0])
    newLogo.append('id', admin._id)
    const options = {
      data: newLogo,
      method: 'POST',
      url: API + "/register-admin/logo"
    }
    const res = await axios.request(options)
    popUp('Logo cambiado correctamente', true)
    console.log(res);
  }

  return (
    <div className="ProfileContainer">
      <header className='ProfileHeader'>
        <img src={logo} alt="Logo" />
        <div className='ProfileHeaderRight' onClick={() => history.push('/trivia/dashboard/')}>
          <img src={ProfileLogo} alt="Perfil" id='profile' />
          <label htmlFor="#profile" className='purpleColor semiBold' >VOLVER</label>
        </div>
      </header>
      <main className='ProfileContent'>
        <div className='ProfileUpdate ProfileOption'>
          <label htmlFor="ProfileUpdate" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={profileUpdate} alt="Cargar Logo" />
            <label htmlFor="ProfileUpdate" className='purpleColor semiBold'>Cargar mi logo</label>
          </label>
          <input id='ProfileUpdate' type="file" accept='.png' onChange={uploadLogo} style={{ display: 'none', visibility: 'hidden' }} />
        </div>
        <div className='ProfileData ProfileOption'>
          <img src={profileData} alt="Datos Generales" id='ProfileData' onClick={() => setDataProfile(!dataProfile)} />
          <label htmlFor="ProfileUpdate" className='purpleColor semiBold'>Datos generales</label>
          {dataProfile ?
            <div className='ProfileDataContent'>
              <p className='purpleColor semiBold'>{admin ? admin.name : ''}</p>
              <p className='purpleColor semiBold'>{admin ? admin.email : ''}</p>
              <button className='purpleColor semiBold'>Cambiar contraseña</button>
            </div>
            : ''}
        </div>
        <div className='ProfileStatistics ProfileOption'>
          <img src={profileStatistics} alt="Estadísticas de trivias" id='ProfileStatistics' />
          <label htmlFor="ProfileUpdate" className='purpleColor semiBold'>Estadísticas de trivias</label>
        </div>
      </main>
      <footer className='ProfileFooter'>
        <img src={logo} alt="Logo Ozel Producciones" />
      </footer>
    </div>
  )
}

export default withRouter(Profile)
