import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TriviaDashBoard from './pages/triviaDashboard/triviaDashBoard'
import TriviaOnline from './pages/triviaOnline/triviaOnline'
import Login from './pages/login/login'
import Home from './pages/home/home'
import Register from './pages/register/register'
import Page404 from './pages/404/page404'
import TriviaEdit from './pages/triviaEdit/triviaEdit';
import Profile from './pages/profile/profile';
import TriviaAvailable from './pages/triviaAvailable/triviaAvailable';
import ManageTrivia from './pages/manageTrivia/manageTrivia';
/* =========================== */
import GameMemoryLogin from './pages-memorygame/gameLogin/gameLogin'
import GameMemoryLobby from './pages-memorygame/gameLobby/gameLobby'
import Game from './pages-memorygame/game/memory'
import Ends from './pages-memorygame/ends/score'

let Routes = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        {/* TRIVIA ROUTES */}
        <Route exact path="/" component={Home} />
        <Route exact path="/trivia/register" component={Register} />
        <Route exact path="/trivia/login/" component={Login}/>
        <Route exact path="/trivia/profile/" component={Profile}/>
        <Route exact path="/trivia/dashboard" component={TriviaDashBoard}/>
        <Route exact path="/trivia/dashboard/available" component={TriviaAvailable}/>
        <Route exact path="/trivia/dashboard/triviaedit/" component={TriviaEdit}/>
        <Route exact path="/trivia/dashboard/triviaedit/:code" component={TriviaEdit}/>
        <Route exact path="/trivia/dashboard/managetrivia/:code" component={ManageTrivia}/>
        <Route exact path="/trivia/triviaonline/" component={TriviaOnline}/>
        <Route path="/trivia/triviaonline/:triviaID/:name/:email" component={TriviaOnline}/>

        {/* MEMORYGAME ROUTES */}
        <Route exact path="/memorygame/login" component={GameMemoryLogin}/>
        <Route exact path="/memorygame/lobby" component={GameMemoryLobby}/>
        <Route exact path="/memorygame/game" component={Game}/>
        <Route exact path="/memorygame/Ends" component={Ends}/>
        <Route component={Page404}/>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
