import { useState, useEffect } from 'react'
import { API } from '../../../config'
import './answers.css'

let Answers = (props) => {
  let { setAns, trivia } = props
  const [answersData, setAnswersData] = useState([])

  useEffect(() => {

    getData()

    return () => { /* Unmount */ }
  }, [])

  let getData = (evt) => {
    let conf = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
      },
      body: JSON.stringify({ trivia: trivia._id })
    }

    fetch(`${API}/player/trivia/`, conf)
      .then(res => res.json())
      .then(res => {
        let tempUsers = []
        let tempAnswObj = []
        let finalUsers

        // GET ALL NAMES
        res.map((ele) => {
          let time = new Date(ele.user.createdAt)
          let dateComp = `Fecha: ${time.getDate() < 10 ? "0"+time.getDate() : time.getDate()}/${time.getMonth() < 10 ? "0"+time.getMonth() : time.getMonth()}/${time.getFullYear()}`
          let timeComp = `Hora: ${time.getHours() < 10 ? "0"+time.getHours() : time.getHours()}:${time.getMinutes() < 10 ? "0"+time.getMinutes() : time.getMinutes()}:${time.getSeconds() < 10 ? "0"+time.getSeconds() : time.getSeconds()}`

          if (tempUsers.length > 0) {
            let sumScore = 0
            if (tempUsers[tempUsers.length - 1].score[ele.user.name] !== undefined) {
              sumScore = tempUsers[tempUsers.length - 1].score[ele.user.name] + ele.score
            } else {
              sumScore = ele.score
            }
            tempUsers.push({
              name: ele.user.name,
              email: ele.user.email,
              loginDate: dateComp,
              loginTime: timeComp,
              score: {[ele.user.name]: sumScore}
            })
          } else {
            tempUsers.push({
              name: ele.user.name,
              email: ele.user.email,
              loginDate: dateComp,
              loginTime: timeComp,
              score: {[ele.user.name]: ele.score}
            })
          }
          return null
        })

        // Sorting by score
        tempUsers = tempUsers.sort((a, b) => {
          if (a.score[a.name] > b.score[b.name]) {
            return -1
          } else {
            return 1
          }
        })

        // REMOVE DUPLICATE DATA
        const seen = new Set();
        finalUsers = tempUsers.filter(ele => {
          const dup = seen.has(ele.name)
          seen.add(ele.name)
          return !dup
        })

        // CREATE ELEMENT BY EACH NAME
        finalUsers.map((ele) => {
          tempAnswObj.push({
            user: ele,
            answers: res.filter((item) => item.user.name === ele.name)
          })
          return null
        })

        setAnswersData(tempAnswObj)
      })
      .catch(err => {
        console.log(err);
      })
  }

  return (
    <div className="answersContainer">
      <button className='purpleColor semiBold whiteBackground RankingBackButton' onClick={() => setAns(false)}>VOLVER</button>
      <h1 className="purpleColor ansTitle">Tabla de respuesta por participante</h1>
      <h2 className="purpleColor ansSub">Pregunta: <span>{answersData[0] === undefined ? "" : answersData[0].answers[0].trivia.projectCode}</span></h2>

      <div className="contentCards">
        {
          answersData
          ? answersData.map((ele, idx) => {
              return (
                <div className="card" key={idx}>
                  <div className="userCont">
                    <div className="userInfo">
                      <h2 className="name">{ele.user.name}</h2>
                      <h3 className="email">{ele.user.email}</h3>
                    </div>

                    <div className="dateCont">
                      <h3 className="date">{ele.user.loginDate}</h3>
                      <h3 className="time">{ele.user.loginTime}</h3>
                    </div>
                  </div>

                  <div className="QA">
                    {
                      ele.answers.map((item, i) => {
                        return (
                          <div className="questionCont" key={i}>
                            <h2 className="nomenclatura">{i + 1}</h2>
                            <h2 className="question">{item.question.text}:</h2>
                            {
                              trivia.question.map((it, il) => {
                                if (item.question._id === it._id) {
                                  return (
                                    <div className="ansCont" key={il}>
                                      {
                                        it.choice.map((e, j) => {
                                          let ansCorr = "generic"
                                          if (item.choice) {
                                            if (item.choice._id === e._id) {
                                              if (item.choice.right === true) {
                                                ansCorr = "correcta"
                                              } else {
                                                ansCorr = "incorrecta"
                                              }
                                            }
                                          }
                                          return (
                                            <h2 className={ansCorr} key={j}>{e.text}</h2>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                } else {
                                  return null
                                }
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="scoreData">
                    <h2 className={ele.user.score[ele.user.name] > (ele.answers.length * 1000) / 2 ? "green" : 'bad'}>SCORE: <span>{ele.user.score[ele.user.name]}</span></h2>
                  </div>
                </div>
              )
            })
          : null
        }
      </div>
    </div>
  )
}

export default Answers
