const modeEnv = process.env.NODE_ENV || 'development';
let port = ''

// check if mode is in developement or production (Build)
if (modeEnv === 'development') port = ":3010" // :8000
if (modeEnv === 'production') port = '' // para demoeventos se debe colocar el mismo puerto del BACK ':3018'


export const API = window.location.protocol + '//' + window.location.hostname + port + '/api/v01'
export const ws = window.location.protocol + "//" + window.location.hostname + port
