import { useState, useEffect } from 'react'
import axios from 'axios'
import { API } from '../../../config'
import place1 from '../../../media/place1.png'
import place2 from '../../../media/place2.png'
import place3 from '../../../media/place3.png'
import logo from '../../../media/LogoOzel.png'
import './ranking.css'

const Ranking = ({ setRanking, trivia }) => {
  const [score, setScore] = useState([])

  useEffect(() => {
    const calculate = async () => {
      if (trivia) {
        try {
          const res = await axios.get(API + "/trivia/score/total/" + trivia._id)
          console.log(res);
          let scoreList = []
          for (const key in res.data) {
            scoreList.push({ name: res.data[key].user.name, email: res.data[key].user.email, score: res.data[key].score, id: key })
          }
          const newList = scoreList.sort((a, b) => {
            if (a.score > b.score) {
              return -1
            } else {
              return 1
            }
          })
          setScore(newList)
        } catch (error) {
          console.error(error);
        }
      }
    }
    calculate()
  }, [trivia])

  return (
    <div className='RankingContainer linearGradientBackgound1'>
      <button className='purpleColor semiBold whiteBackground RankingBackButton' onClick={() => setRanking(false)}>VOLVER</button>
      <div className='RankingContent'>
        <div>
          <h1 className='whiteColor noBold RankingTitle'>RANKING</h1>
        </div>
        <div className='RankingPlaces'>
          <div className='RankingPlace secondPlace'>
            <img src={place2} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor noBold'>{score[1] ? score[1].name : ''}</h4>
            </div>
          </div>
          <div className='RankingPlace firstPlace'>
            <img src={place1} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor noBold'>{score[0] ? score[0].name : ''}</h4>
            </div>
          </div>
          <div className='RankingPlace thirdPlace'>
            <img src={place3} alt="Segundo lugar" />
            <div>
              <h4 className='whiteColor noBold'>{score[2] ? score[2].name : ''}</h4>
            </div>
          </div>
        </div>
        <div className='RankingList'>
          {score ? score.map((winner, i) => (
            <div className='RankingListItem' key={winner._id + i}>
              <h4 className='whiteColor noBold'>#{i + 1}</h4>
              <div>
                <p>{winner.name}</p>
                <p>{winner.email}</p>
                <p>{winner.score}</p>
              </div>
            </div>
          )) : ''}
        </div>
      </div>
      <img src={logo} alt="" className='RankingImgFooter' />
    </div>
  )
}

export default Ranking