import { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'universal-cookie';
import { API } from '../../config'
import logo from '../../media/LogoOzel2.png'
import './triviaAvailable.css'
import SignOff from '../../components/signOff';

const cookies = new Cookies();

const TriviaAvailable = ({ history }) => {
  const [trivias, setTrivias] = useState(null)
  const [admin, setAdmin] = useState(null)

  useEffect(() => {
    document.title = "Ozel play | Trivias disponibles"
    const getAdmin = async () => {
      const auxAdmin = await cookies.get('admin')
      if (!auxAdmin || auxAdmin === 'null') {
        history.push('/trivia/login')
        return
      }
      setAdmin(auxAdmin)
    }
    const aux = async () => {
      const options = {
        data: { admin: admin._id },
        method: 'POST',
        url: API + "/trivia/list",
        headers: { "Content-Type": "application/json" }
      }
      const res = await axios.request(options)
      setTrivias(res.data)
    }
    if (!trivias && admin) {
      aux()
    }
    if (!admin) {
      getAdmin()
    }
  }, [trivias, history, admin])

  return (
    <div className="AvailableContainer">
      <header className='AvailableHeader'>
        {admin ?
          <img src={`${API}/register-admin/logo/${admin._id}.png`} alt="Logo" onError={(e) => e.target.src = logo} />
          :
          <img src={logo} alt="Logo" />
        }
        <div className='AvailableHeaderRightItem'>
          <SignOff history={history} />
        </div>
      </header>
      <main className='AvailableContent'>
        <div className='AvailableTitle purpleBackground whiteColor'>
          <h1>Trivias Disponibles</h1>
        </div>
        <div className='AvailableTrivias'>
          {trivias ? trivias.map(trivia => (
            <div className='AvailableItem' key={trivia._id}>
              <button className='purpleBackground whiteColor semiBold' onClick={() => history.push('/trivia/dashboard/managetrivia/' + trivia.code)}>{trivia.projectCode}</button>
            </div>
          )) : ''}
        </div>
        <button className='purpleColor semiBold whiteBackground AvailableBackButton' onClick={() => history.push('/trivia/dashboard/')}>VOLVER</button>
      </main>
      <footer className='AvailableFooter'>
        <img src={logo} alt="Logo Ozel Producciones" />
      </footer>
    </div>
  )
}

export default withRouter(TriviaAvailable)
