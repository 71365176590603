import React from "react"
import { withRouter } from "react-router"
import Cookies from 'universal-cookie'
import logo from '../../images/LogoOzelColor.png'
import { API } from "../../config"
import {
  imagesD1,
  imagesD2,
  imagesD3,
  imagesD4,
} from '../../images/memory/images'

import './memory.scss'

const cookies = new Cookies();

const frameworks = {
  "1": [
    imagesD1.Ficha1_D1,
    imagesD1.Ficha2_D1,
    imagesD1.Ficha3_D1,
    imagesD1.Ficha4_D1,
    imagesD1.Ficha5_D1,
    imagesD1.Ficha6_D1,
    imagesD1.Ficha7_D1,
    imagesD1.Ficha8_D1,
  ],
  "2": [
    imagesD2.Ficha1_D2,
    imagesD2.Ficha2_D2,
    imagesD2.Ficha3_D2,
    imagesD2.Ficha4_D2,
    imagesD2.Ficha5_D2,
    imagesD2.Ficha6_D2,
    imagesD2.Ficha7_D2,
    imagesD2.Ficha8_D2,
  ],
  "3": [
    imagesD3.Ficha1_D3,
    imagesD3.Ficha2_D3,
    imagesD3.Ficha3_D3,
    imagesD3.Ficha4_D3,
    imagesD3.Ficha5_D3,
    imagesD3.Ficha6_D3,
    imagesD3.Ficha7_D3,
    imagesD3.Ficha8_D3,
  ],
  "4": [
    imagesD4.Ficha1_D4,
    imagesD4.Ficha2_D4,
    imagesD4.Ficha3_D4,
    imagesD4.Ficha4_D4,
    imagesD4.Ficha5_D4,
    imagesD4.Ficha6_D4,
    imagesD4.Ficha7_D4,
    imagesD4.Ficha8_D4,
  ],
}

const covers = {
  "1": imagesD1.Portada_1,
  "2": imagesD2.Portada_2,
  "3": imagesD3.Portada_3,
  "4": imagesD4.Portada_4
}

class PlayGround extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        frameworks: frameworks['1'],
        cover: covers['1'],
        duplicatedFrameworks: [],
        randomizedFrameworks: [],
        finalizedFrameworks: [],
        openedFrameworks: [],
        gamerData: cookies.get('playedGamer'),
        gameData: {},
        type: cookies.get('urlData'),
        time: {},
        seconds: 30,
        elapsedTime: 0
      }
      this.timer = 0
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
    }

    async componentDidMount() {
      // Runs after the first render() lifecycle
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.state.frameworks = frameworks[this.state.type.type]
      this.state.cover = covers[this.state.type.type]

      const res = await fetch(`${API}/concentrese/code/${this.state.gamerData.code}`)
      const data = await res.json()

      if (data.timeOut) {
        this.state.gameData = data
        this.state.seconds = data.timeOut
        this.start()
        this.startTimer()
      }
    }

    startTimer() {
      if (this.timer == 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }

    countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1
      let elapsedSec = this.state.elapsedTime + 1
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        elapsedTime: elapsedSec
      });

      // Check if we're at zero.
      if (seconds == 0) {
        clearInterval(this.timer);
        this.props.history.push(`/memorygame/Ends`)
      }
    }


    secondsToTime(secs){
      let hours = Math.floor(secs / (60 * 60));

      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);

      let obj = {
        "h": hours,
        "m": minutes,
        "s": seconds
      };
      return obj;
    }






    handleClick(name,index){
      if(this.state.openedFrameworks.length === 2){
        setTimeout(() => {
          try {
            this.check()
          } catch (err) {
            console.log(err);
          }

        },750)
      }else {
        let framework = {
          name,
          index
        }
        let finalizedFrameworks = this.state.finalizedFrameworks
        let frameworks = this.state.openedFrameworks
        finalizedFrameworks[index].close = false
        frameworks.push(framework)
        this.setState({
          openedFrameworks: frameworks,
          finalizedFrameworks: finalizedFrameworks
        })
        if(this.state.openedFrameworks.length === 2){
          setTimeout(() => {
            this.check()
          },750)
        }
      }
    }

    check(){
      let finalizedFrameworks = this.state.finalizedFrameworks
      if((this.state.openedFrameworks[0].name === this.state.openedFrameworks[1].name) && (this.state.openedFrameworks[0].index !== this.state.openedFrameworks[1].index)){
        finalizedFrameworks[this.state.openedFrameworks[0].index].complete = true
        finalizedFrameworks[this.state.openedFrameworks[1].index].complete = true
      }else {
        finalizedFrameworks[this.state.openedFrameworks[0].index].close = true
        finalizedFrameworks[this.state.openedFrameworks[1].index].close = true
      }
      this.setState({
        finalizedFrameworks,
        openedFrameworks: []
      })

      if (finalizedFrameworks.every(e => e.complete === true)) {
        let data = {
          time: parseInt(this.state.elapsedTime),
          code: this.state.gameData.code,
          email: this.state.gamerData.email
        }

        let conf = {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }

        fetch(`${API}/playerConcentrese`, conf)
          .then(res => res.json())
          .then(res => {

            this.props.history.push(`/memorygame/Ends`)
          })
          .catch(err => {
            console.log(err);
          })
      }
    }

    start(){
      let finalizedFrameworks = [];
      this.state.duplicatedFrameworks = this.state.frameworks.concat(this.state.frameworks)
      this.state.randomizedFrameworks = this.shuffle(this.state.duplicatedFrameworks)
      this.state.randomizedFrameworks.map((name,index) => {
        finalizedFrameworks.push({
          name,
          close: true,
          complete: false,
          fail: false
        })
      })
      this.state.finalizedFrameworks = finalizedFrameworks
    }
    shuffle(array){
      let currentIndex = array.length, temporaryValue, randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }
      return array
    }
    render(){
      return (
        <div className="gameCont">
          <div className="gameContainer">
            <h1>{this.state.gameData.projectCode}</h1>
            <h2>Tiempo restante: <span>{this.state.time.m < 10 ? `0${this.state.time.m}` : this.state.time.m}:{this.state.time.s < 10 ? `0${this.state.time.s}` : this.state.time.s}</span></h2>
            <h2>Tiempo transcurrido: <span>{this.state.elapsedTime}s</span></h2>
            <div className="playground">
              {
                this.state.finalizedFrameworks.map((framework, index) => {
                  return <Card framework={framework.name} click={() => {this.handleClick(framework.name,index)}} close={framework.close} complete={framework.complete} key={index} cover={this.state.cover}/>
                })
              }
            </div>
          </div>
        </div>
      )
    }
}

class Card extends React.Component {
  constructor(props) {
      super(props)
      this.state = {}
    }
  clicked(framework){
    this.props.click(framework)
  }
  render(){
    return (
      <div className={"card" + (!this.props.close ? ' opened' : '') + (this.props.complete ? ' matched' : '')} onClick={() => this.clicked(this.props.framework)}>
        <div className="front">
          <div className="frontCardImg">
            <img src={ this.props.cover } alt="Ozel" />
          </div>
        </div>
        <div className="back">
          <div className="backCardImg">
            <img src={this.props.framework}/>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(PlayGround)
