import { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { API } from '../../config'
import Cookies from 'universal-cookie';
import axios from 'axios'
import QuestionEdit from './questionEdit/questionEdit'
import logo from '../../media/LogoOzel2.png'
import eye from '../../media/eye.png'
import edit from '../../media/edit.png'
import trash from '../../media/trash.png'
import popUp from '../../components/popUp'
import './triviaEdit.css'
import Preview from '../../components/triviaPreview/preview'

const cookies = new Cookies();

const TriviaEdit = ({ history, match }) => {
  const [admin, setAdmin] = useState(null)
  const [state, setState] = useState(0)
  const [selectedQuestion, setSelectedQuestion] = useState(0)
  const [preview, setPreview] = useState(null)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [trivia, setTrivia] = useState({
    admin: '', projectCode: '', mediaUrl: "", question: [{ text: "", timeout: 30000, timeToThink: 3000, mediaUrl: "", choice: [{ text: "", right: true }, { text: "", right: false }] }]
  })
  const [code] = useState(match.params.code)

  useEffect(() => {
    const getTrivia = async () => {
      if (code) {
        try {
          const res = await axios.get(API + '/trivia/code/' + code)
          setTrivia(res.data)
        } catch (error) {
          popUp('Trivia no encontrada', false)
          history.push('/')
          console.error(error);
          return false
        }
      }
    }
    getTrivia()
  }, [code, history])

  useEffect(() => {
    const getAdmin = async () => {
      const auxAdmin = await cookies.get('admin')
      if (!auxAdmin || auxAdmin === 'null') {
        history.push('/trivia/login')
        return
      }
      setAdmin(auxAdmin)
    }
    if (!admin) {
      getAdmin()
    }
  }, [admin, history])

  useEffect(() => {
    let areYouReallySure = false;

    function areYouSure() {
      if (allowPrompt) {
        if (!areYouReallySure && true) {
          areYouReallySure = true;
          let confMessage = "Puedes perder informacion si cierras o recargas la pagina";
          return confMessage;
        }
      } else {
        allowPrompt = true;
      }
    }
    var allowPrompt = true;
    window.onbeforeunload = areYouSure;
  }, [])


  const addQuestion = () => {
    let auxTrivia = { ...trivia }
    auxTrivia.question.push({ text: "", timeout: 30000, timeToThink: 3000, mediaUrl: "", choice: [{ text: "", right: true }, { text: "", right: false }] })
    setTrivia(auxTrivia)
  }

  const deleteQuestion = (i) => {
    if (trivia.question.length > 1) {
      let auxTrivia = { ...trivia }
      auxTrivia.question.splice(i, 1)
      setTrivia(auxTrivia)
    }
  }

  const select = (i) => {
    setState(1)
    setSelectedQuestion(i)
  }

  const saveTrivia = async () => {
    if (trivia.projectCode.trim() === '') {
      popUp('Falta el nombre de la trivia', 'warning')
      return
    }
    for (const question of trivia.question) {
      if (question.text.trim() === '') {
        popUp('Hay preguntas sin completar', 'warning')
        return
      }
    }
    const auxTrivia = { ...trivia, admin: admin._id }
    const options = {
      data: auxTrivia,
      method: code ? 'PATCH' : 'POST',
      url: code ? API + "/trivia/" + auxTrivia._id : API + "/trivia/",
      headers: { "Content-Type": "application/json" }
    }

    try {
      const res = await axios.request(options)
      popUp('Trivia creada', true, false, 5000)
      history.push('/trivia/dashboard/managetrivia/' + res.data.code)
    } catch (error) {
      popUp('Algo fallo', false)
      console.error(error.response.data);
    }
  }

  if (preview) {
    return (
      <Preview trivia={trivia} currentQuestion={currentQuestion} setPreview={setPreview} />
    )
  }

  switch (state) {
    case 1:
      return (
        <QuestionEdit trivia={trivia} setTrivia={setTrivia} question={selectedQuestion} setState={setState} admin={admin} />
      )
    default:
      return (
        <div className="TriviaEditContainer">
          <header className='TriviaEditHeader'>
            {admin ?
              <img src={`${API}/register-admin/logo/${admin._id}.png`} alt="Logo" onError={(e) => e.target.src = logo} />
              :
              <img src={logo} alt="Logo" />
            }
          </header>
          <main className='TriviaEditContent purpleColor'>
            <div className='TriviaEditContentHeader'>
              <input className='TriviaEditName purpleColor' type="text" name="triviaName" placeholder='Nombre de la trivia' value={trivia.projectCode} onChange={e => setTrivia({ ...trivia, projectCode: e.target.value })} />
            </div>
            <div className='TriviaEditPanel'>
              <div className='TriviaEditOption'>
                <h1>PREGUNTAS</h1>
                {trivia && trivia.question ? trivia.question.map((question, i) => (
                  <div key={question.text + i} className='TriviaEditQuestion'>
                    <h3>{question.text && question.text !== '' ? question.text : 'PREGUNTA ' + (i + 1)}</h3>
                    <div className='TriviaEditQuestionButtons'>
                      <img src={eye} alt="ver" onClick={() => { setCurrentQuestion(i); setPreview(true) }}/>
                      <img src={edit} alt="editar" onClick={() => select(i)} />
                      <img src={trash} alt="borrar" onClick={() => deleteQuestion(i)} />
                    </div>
                  </div>
                )) : ''}
                <div className='TriviaEditButtons'>
                  <button className='purpleColor semiBold whiteBackground' onClick={addQuestion}>+ Agregar pregunta</button>
                </div>
                {/* <div className='TriviaEditQuestion'>
                  <h2>RANKING</h2>
                  <div className='TriviaEditQuestionButtons'>
                    <img src={eye} alt="ver" />
                  </div>
                </div> */}
              </div>
              {/* <div className='TriviaEditOption'>
                <h1>CUSTOMIZAR DISEÑOS</h1>
                <div className='TriviaEditConfig'>
                  <div><h3>Cambiar fondo</h3></div>
                  <div><h3>Fuente</h3></div>
                </div>
              </div> */}
            </div>
            <div className='TriviaEditButtons'>
              <button className='purpleColor semiBold whiteBackground' onClick={() => history.push('/trivia/dashboard')}>VOLVER</button>{/* PUBLICAR TRIVIA */}
              <button className='purpleColor semiBold whiteBackground' onClick={saveTrivia}>GUARDAR TRIVIA</button>
            </div>
          </main>
          <footer className='TriviaEditFooter'>
            <img src={logo} alt="Logo Ozel Producciones" />
          </footer>
        </div>
      )
  }
}

export default withRouter(TriviaEdit)
