import { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import logo from '../../media/LogoOzel.png'
import { withRouter } from 'react-router-dom'
import { API } from '../../config'
import popUp from '../../components/popUp'
import './register.css'

let Register = ({ history }) => {
  const [user, setUser] = useState({ name: '', email: '', password: '', sector: 'Educativo', repeatPassword: '', checkbox: false })

  const onchangeHandler = (e) => {
    if (e.target.name !== 'checkbox') {
      setUser({ ...user, [e.target.name]: e.target.value })
    } else {
      setUser({ ...user, [e.target.name]: e.target.checked })
    }
  }

  const submitRegister = useCallback(async (e) => {
    if ((e && e.key !== 'Enter') && (e && e.type !== 'click')) return
    if (user.name.trim() === '' || user.email.trim() === '' || user.password.trim() === '' || user.repeatPassword.trim() === '') {
      popUp('Faltan campos por llenar', 'warning')
      return
    } else {
      if (!user.checkbox) {
        popUp('Aún no aceptas terminos y condiciones', 'warning')
        return
      } else if (user.password !== user.repeatPassword) {
        popUp('Contraseña no coincide', 'warning')
        return
      }
      const options = {
        data: { name: user.name.trim(), email: user.email.trim().toLowerCase(), sector: user.sector, password: user.password },
        method: 'POST',
        url: API + "/register-admin/",
        headers: { "Content-Type": "application/json" }
      }
      try {
        const res = await axios.request(options)
        console.log(res);
        popUp('Registro exitoso', true, false, 5000)
        history.push('/trivia/login')
      } catch (error) {
        popUp('Error al registrar', false, false, 5000)
        console.error(error);
      }
    }// eslint-disable-next-line
  }, [user])

  useEffect(() => {
    window.addEventListener('keyup', submitRegister, true)
    return () => {
      window.removeEventListener('keyup', submitRegister, true)
    }
  }, [user, submitRegister])


  return (
    <div className="RegisterContainer linearGradientBackgound1">
      <div className='RegisterHeader'>
        <img src={logo} alt='Logo de Ozel' />
      </div>
      <div className="RegisterContent">
        <form className='RegisterFormContainer'>
          <div className='RegisterForm whiteBackground purpleColor'>
            <div className='RegisterFormInput'>
              <label htmlFor='name'>Nombre</label>
              <input type="text" name='name' className='purpleColor' value={user.name} onChange={onchangeHandler} />
            </div>
            <div className='RegisterFormInput'>
              <label htmlFor='email'>Correo</label>
              <input type="email" name='email' className='purpleColor' value={user.email} onChange={onchangeHandler} />
            </div>
            {/* <div className='RegisterFormInput'>
              <label htmlFor='phone'>Teléfono</label>
              <input type="number" id='phone' className='purpleColor' />
            </div>*/}
            <div className='RegisterFormInput'>
              <label htmlFor='sector'>Elige el sector al que perteneces</label>
              <select name="sector" id="sector" className='purpleColor' value={user.sector}>{/* AGREGAR OPCIONES */}
                <option value="Educativo" className='purpleColor'>Educativo</option>
              </select>
            </div>
            <div className='RegisterFormInput'>
              <label htmlFor='password'>Contraseña</label>
              <input type="password" name='password' className='purpleColor' value={user.password} onChange={onchangeHandler} />
            </div>
            <div className='RegisterFormInput'>
              <label htmlFor='repeatPassword'>Confirmar contraseña</label>
              <input type="password" name='repeatPassword' className='purpleColor' value={user.repeatPassword} onChange={onchangeHandler} />
            </div>
          </div>
          <div className='RegisterFormCheckbox whiteBackground purpleColor'>
            <input type="checkbox" name='checkbox' checked={user.checkbox} onChange={onchangeHandler} />
            <label htmlFor='checkbox'>Tratamiento de datos</label>
          </div>
        </form>
        <div className='RegisterOptionsContainer'>
          <button className='RegisterOptionButton whiteBackground purpleColor semiBold' onClick={submitRegister}>CREAR CUENTA</button>
          <button className='RegisterOptionButton whiteBackground purpleColor semiBold' onClick={() => history.push('/trivia/login')}>INGRESAR A TU PERFIL</button>
          <button className='RegisterOptionButton whiteBackground purpleColor semiBold' onClick={() => history.push('/trivia/triviaonline')}>INGRESAR A LA TRIVIA CON UN CÓDIGO</button>
        </div>
        <img src={logo} alt="" className='RegisterFooterImg' />
      </div>
    </div>
  )
}

export default withRouter(Register)
