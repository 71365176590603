import React, { useCallback, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { API } from '../../config'
import axios from 'axios'
import Cookies from 'universal-cookie'
import popUp from '../../components/popUp'

import './gameLogin.css'
import logo from '../../media/LogoOzel.png'

const GameLogin = (props) => {
  const cookies = new Cookies();
  const { history } = props

  const [code, setCode] = useState('')
  const [userForm, setUserForm] = useState({ name: '', email: '', checkbox: false })
  const [urlData, setUrlData] = useState({
    name: "",
    email: "",
    checkbox: false
  })

  useEffect(() => {

    document.title = "Ozel play | Game memory login"


    if (window.location.search !== "") {
      let userPlayed = cookies.get("playedGamer")
      let queryData = decodeURIComponent(window.location.search).split('?')[1].split('&&')
      let userData = {}
      queryData.map(ele => {
        userData[ele.split('=')[0]] = ele.split('=')[1]
      })
      cookies.set(`urlData`, userData, { secure: false, path: '/', sameSite: true, maxAge: 259200 })
      setUrlData({
        ...urlData,
        email: userData.email,
        name: userData.name,
        logo: userData.logo,
        checkbox: true,
        code: userData.code
      })
      setUserForm({
        ...userForm,
        email: userData.email,
        name: userData.name,
        checkbox: true
      })
    }

    return () => { /* Unmount */ }
  }, [])

  const getTrivia = useCallback(async (code) => {
    try {
      const res = await axios.get(API + '/concentrese/code/' + code)
      return res.data
    } catch (error) {
      console.error(error);
      return false
    }

    // eslint-disable-next-line
  }, [])

  const getScore = useCallback(async (email, codeG) => {
    try {
      const res = await axios.get(API + `/playerConcentrese/player/${email}/${codeG}`)
      if (res.data.length === 0) {
        return false
      } else {
        return true
      }
    } catch (error) {
      console.error(error);
      return false
    }

    // eslint-disable-next-line
  }, [])

  const addUser = async (evt) => {
    evt.preventDefault()

    if (!userForm.name || !userForm.email || !code) {
      popUp('Faltan campos por llenar', 'warning')
      return
    }
    if (!userForm.checkbox) {
      popUp('Aún no aceptas terminos y condiciones', 'warning')
      return
    }

    const scoreG = await getScore(userForm.email, code)

    if (scoreG) {
      popUp('Ya has jugado, revisa tu puntaje en el perfil', 'warning')
      return
    }

    const triviaRes = await getTrivia(code)

    if (triviaRes) {
      let options = {
        data: { name: userForm.name.trim(), email: userForm.email.trim().toLowerCase() },
        method: 'POST',
        url: API + "/register/",
        headers: { "Content-Type": "application/json" }
      }
      axios.request(options)
        .then(res => {
          console.log(res.data);
          if (res.data._id) {
            res.data['code'] = code
            cookies.set(`playedGamer`, res.data, { secure: false, path: '/', sameSite: true, maxAge: 259200 })
            history.push('/memorygame/lobby')
          }
        })
        .catch(err => {
          console.log(err);
        })
    } else {
      popUp(`Juego ${code} no encontrado`, false)
    }
  }

  return (
    <div className={`CodeContainer linearGradientBackgound1 ${urlData.name && urlData.email ? "embebed" : ""}`}>
      <header className='CodeHeader'>
        {
          urlData.name === "" && urlData.email === "" &&
          <img src={logo} alt='Logo de Ozel' onClick={() => history.push('/')}/>
        }
      </header>
      <div className="CodeContent">
        <h1 style={{ color: "#FFF9" }}>Ingreso a Concéntrese: {/* {urlData.code} */}</h1>
        <form className='CodeFormContainer'>
          <div className={`CodeForm whiteBackground ${urlData.email ? "" : 'purpleColor'}`}>
            {
              urlData.name === "" && urlData.email === "" &&
              <div className='CodeFormInput'>
                <label htmlFor='name'>Nombre</label>
                <input type="text" id='name' className='purpleColor' name='name' value={userForm.name} onChange={e => setUserForm({ ...userForm, name: e.target.value.trim() })} />
              </div>
            }
            {
              urlData.name === "" && urlData.email === "" &&
              <div className='CodeFormInput'>
                <label htmlFor='email'>Correo electrónico</label>
                <input type="email" id='email' className='purpleColor' name='email' value={userForm.email} onChange={e => setUserForm({ ...userForm, email: e.target.value.trim() })} />
              </div>
            }
            <div className='CodeFormInput'>
              <label htmlFor='code'>Código</label>
              <input
                type="number"
                id='code'
                className={`${urlData.email ? "" : 'purpleColor'}`}
                name='code'
                value={code}
                onChange={e => {
                  setUrlData({ ...urlData, code: e.target.value.trim() })
                  setCode(e.target.value.trim())}
                }
              />
            </div>
          </div>
          {
            urlData.name === "" && urlData.email === "" &&
            <div className='RegisterFormCheckbox whiteBackground purpleColor'>
              <input type="checkbox" name='checkbox' checked={userForm.checkbox} onChange={e => setUserForm({ ...userForm, checkbox: e.target.checked })} />
              <label htmlFor='checkbox'>Tratamiento de datos</label>
            </div>
          }
          <button className={`CodeOptionButton whiteBackground ${urlData.email ? "" : 'purpleColor'} semiBold`} onClick={addUser}>INGRESAR</button>
        </form>
        <img src={urlData.logo ? urlData.logo : logo} alt="" className='CodeFooterImg' />
      </div>
    </div>
  )
}

export default withRouter(GameLogin)
