//import React, { useEffect, useState } from 'react'
//import axios from 'axios'
import logo from '../../../media/LogoOzel.png'

import './welcome.css'

const Welcome = ({ setState, setCurrentQuestion, trivia }) => {

  const start = () => {
    setState(4)
    setCurrentQuestion(0)
  }

  return (
    <div className='WelcomeContainer linearGradientBackgound1'>
      <div className='WelcomeContent'>
        <h1 className='whiteColor'>BIENVENIDOS</h1>
        <p className='whiteColor'>Recuerda que solo tienes una oportunidad para responder, así que debes tener en cuenta lo siguiente:</p><br />
        <p className='whiteColor'>1. Antes de iniciar verifica tener una buena conexión a internet.</p>
        <p className='whiteColor'>2. No salir de la plataforma mientras estás participando en ella.</p>
        <p className='whiteColor'>3. Recuerda que tienes un tiempo límite, debes estar pendiente de tu reloj.</p>
        {trivia && trivia.state === 'started' ?
          <button className='WelcomeButton semiBold' onClick={start}>EMPEZAR</button>
          : <p className='redColor'>Aún no empieza la trivia</p>}
      </div>
      <img src={logo} alt="" className='WelcomeImgFooter' />
    </div>
  )
}

export default Welcome
