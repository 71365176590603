import { useState, useEffect } from 'react'
//import UploadMedia from '../../../components/inputs/uploadImage/uploadMedia'
import trash from '../../../media/trash.png'
import popUp from '../../../components/popUp'
import logo from '../../../images/logoOzelBlanco.png'
import { API } from '../../../config'

import './questionEdit.css'

let QuestionEdit = ({ trivia, setTrivia, question, setState, admin }) => {
  const [iframe, setIframe] = useState(trivia.question[question].mediaUrl)
  const [timeout, setTimeout] = useState(trivia.question[question].timeout / 1000)
  const [timeToThink, setTimeToThink] = useState(trivia.question[question].timeToThink / 1000)
  // eslint-disable-next-line
  const [file, setFile] = useState(null)
  const [questionTitle, setQuestionTitle] = useState(trivia.question[question].text)
  const [answers, setAnswers] = useState(trivia.question[question].choice)

  let videoTypes = ["video/mp4", "video/x-msvideo", "video/x-ms-wmv"]

  useEffect(() => {
    document.title = "Ozel play | Edicion de pregunta"
  }, [])

  const onChangeAnswers = (e, i) => {
    let answersAux = [...answers]
    answersAux[i].text = e.target.value
    setAnswers(answersAux)
  }

  const saveQuestion = () => {
    if (questionTitle.trim() === '') {
      popUp('Aún no escribes la pregunta', 'warning')
      return
    }
    for (const answer of answers) {
      if (answer.text.trim() === '') {
        popUp('Respuestas sin completar', 'warning')
        return
      }
      if (timeout < 5) {
        popUp('El tiempo minimo son 5 segundos', 'warning')
        return
      }
    }
    let auxTrivia = { ...trivia }
    auxTrivia.question[question] = { text: questionTitle, timeout: timeout * 1000, timeToThink: timeToThink * 1000, mediaUrl: iframe, choice: answers }//{ text: "", timeout: 0, mediaUrl: "", choice: [{ text: "", right: true }, { text: "", right: false }] }
    setTrivia(auxTrivia)
    setState(0)
  }

  const addAnswer = () => {
    if (answers && answers.length < 4) {
      let auxAnswer = [...answers]
      auxAnswer.push({ text: "", right: false })
      setAnswers(auxAnswer)
    }
  }

  const deleteAnswer = (i) => {
    if (answers.length > 2) {
      let auxAnswer = [...answers]
      auxAnswer.splice(i, 1)
      setAnswers(auxAnswer)
    }
  }

  const changeRigth = (choice, i) => {
    if (!choice.right) {
      let auxAnswer = [...answers]
      for (const item of auxAnswer) {
        if (item.right) {
          item.right = false
          auxAnswer[i].right = true
        }
      }
      setAnswers(auxAnswer)
    }
  }

  return (
    <div className="questionEditContainer">
      <header>
        <img src={`${API}/register-admin/logo/${admin._id}.png`} alt="Logo" onError={(e) => e.target.src = logo} />
      </header>

      <div className="body">
        {/* <div className="leftPanel">
          mini settings
        </div> */}

        <div className="centerPanel">
          <div className="imgContainer">
            {
              iframe === "" && file !== null
                ? videoTypes.indexOf(file.type) >= 0 ? <video controls><source src={URL.createObjectURL(file)} type={file.type} /> Esta APP no acepta este tipo de archivos</video> : <div className="prevImg" style={{ backgroundImage: `url(${URL.createObjectURL(file)})` }}></div>
                : <div className="iframeCont" dangerouslySetInnerHTML={{ __html: iframe }}></div>
            }
            <div className="actions">
              <div className={"inputContainer inputIframe"}>
                <input type="text" placeholder='Insertar Iframe' className='inputIframe' value={iframe} onChange={(e) => setIframe(e.target.value)} required />
              </div>
            </div>
          </div>

          <div className="questionCont">
            <textarea className="questionTitle" placeholder="Pregunta" value={questionTitle} onChange={e => { setQuestionTitle(e.target.value) }}></textarea>
            <div className="answersCont">
              {answers ? answers.map((item, i) => (
                <div className="answer" key={i}>
                  <textarea className="answerInput" placeholder="Añadir respuesta" value={item.text} onChange={e => onChangeAnswers(e, i)}></textarea>
                  <div className={item.right ? 'answerRight' : 'answerOption'} onClick={() => changeRigth(item, i)}>
                    <h1>&#10004;</h1>
                  </div>
                  <img src={trash} alt="borrar" onClick={() => deleteAnswer(i)} />
                  {/* <div className="actions">
                  <UploadMedia text="Insertar imagen o video" id="upImgAns" maxSize="2048" state={{ file: [file, setFile] }} />
                  <button className="correctBtn">&#10003;</button>
                  <button className="remove">X</button>
                </div> */}
                </div>
              )) : ''}
              {answers && answers.length && answers.length < 4 ?
                <div className="answer">
                  <button className="answerInput" onClick={addAnswer}>Añadir respuesta</button>
                </div>
                : ''}
            </div>
          </div>
        </div>

        <div className="rightPanel purpleColor">
          <div className='rightPanelOption'>
            <p className='purpleColor semiBold'>Tiempo límite en segundos</p>
            <input type="number" name="timeout" value={timeout} onChange={e => setTimeout(e.target.value)} className='semiBold purpleColor' />
          </div>
          <div className='rightPanelOption'>
            <p className='purpleColor semiBold'>Tiempo antes de mostrar respuestas</p>
            <input type="number" name="timeToThink" value={timeToThink} onChange={e => setTimeToThink(e.target.value)} className='semiBold purpleColor' />
          </div>
          <div className='rightPanelOption'>
            <button onClick={saveQuestion} className='purpleColor semiBold'>Guardar</button>
            <button onClick={() => setState(0)} className='purpleColor semiBold'>Volver</button>
          </div>

        </div>
      </div>

      <footer>
        <div className="ozelLogo"></div>
        <h2>Ozel producciones</h2>
      </footer>
    </div>
  )
}
export default QuestionEdit
