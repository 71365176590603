import React, { useState, useEffect, useCallback } from 'react'
import Preview from '../../components/triviaPreview/preview'
import { withRouter } from 'react-router-dom'
import io from 'socket.io-client'
import popUp from '../../components/popUp'
import { API, ws } from '../../config'
import Cookies from 'universal-cookie';
import Ranking from './ranking/ranking'
import Answers from './answers/answers'
import axios from 'axios'
import logo from '../../media/LogoOzel2.png'
import eye from '../../media/eye.png'
import './manageTrivia.css'

const cookies = new Cookies();

let socket = null

const ManageTrivia = (props) => {
  const [trivia, setTrivia] = useState(null)
  const [admin, setAdmin] = useState(null)
  const [ranking, setRanking] = useState(false)
  const [answers, setAnswers] = useState(false)
  const [preview, setPreview] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [userList, setUserList] = useState([])

  const { history } = props

  const code = props && props.match && props.match.params && props.match.params.code ? props.match.params.code : null

  const getTrivia = useCallback(async () => {
    if (code) {
      try {
        const res = await axios.get(API + '/trivia/code/' + code)
        setTrivia(res.data)
        return res.data
      } catch (error) {
        console.error(error);
        return false
      }
    }
  }, [code])

  useEffect(() => {
    const getAdmin = () => {
      const auxAdmin = cookies.get('admin')
      if (!auxAdmin || auxAdmin === 'null') {
        props.history.push('/trivia/login')
        return
      }
      setAdmin(auxAdmin)
    }
    if (!admin) {
      getAdmin()
    } else {
      getTrivia()
    }
    socket = null
  }, [getTrivia, props, admin])


  useEffect(() => {
    if (trivia && (!socket || !socket.connected)) {
      socket = io.connect(ws, { transports: ['websocket'] })
      console.log("EMITIENDO");
      socket.emit('adminJoin', code)
      socket.on('userList', (users) => {
        setUserList(users)
      })
      socket.on('changeState', ({ state }) => {
        console.log("CAMBIO DE ESTADO " + state);
        setTrivia({ ...trivia, state: state })
      })
    } else if (socket) {
      socket.on('userList', (users) => {
        setUserList(users)
      })
      socket.on('changeState', ({ state }) => {
        console.log("CAMBIO DE ESTADO " + state);
        setTrivia({ ...trivia, state: state })
      })
    }
    return () => {
      if (socket) {
        socket.off()
      }
    }
  }, [trivia, code, answers, ranking, preview])

  const changeState = () => {
    if (socket && (!trivia || !trivia.state || trivia.state !== 'started')) {
      socket.emit('changeState', { code: trivia.code, state: 'started' })
    } else if (socket) {
      socket.emit('changeState', { code: trivia.code, state: 'finished' })
    }
  }

  const deleteTrivia = async (id) => {
    try {
      await axios.delete(API + '/trivia/' + id)
      props.history.push('/trivia/dashboard/available')
      popUp('Trivia eliminada', true)
    } catch (error) {
      console.error(error);
    }
  }

  const duplicate = async () => {
    let questionAux = []
    for (const question of trivia.question) {
      let choicesAux = []
      for (const choice of question.choice) {
        choicesAux.push({ right: choice.right, text: choice.text })
      }
      questionAux.push({ choice: choicesAux, mediaUrl: question.mediaUrl, text: question.text, timeout: question.timeout, timeToThink: question.timeToThink })
    }
    let triviaAux = { admin: admin._id, mediaUrl: trivia.mediaUrl, projectCode: trivia.projectCode + ' copy', question: questionAux }
    const options = {
      data: triviaAux,
      method: 'POST',
      url: API + "/trivia/",
      headers: { "Content-Type": "application/json" }
    }

    try {
      const res = await axios.request(options)
      popUp('Trivia creada', true, false, 5000)
      history.push('/trivia/dashboard/triviaedit/' + res.data.code)
    } catch (error) {
      popUp('Algo fallo', false)
      console.error(error.response.data);
    }
  }

  if (preview) {
    return (
      <Preview trivia={trivia} currentQuestion={currentQuestion} setPreview={setPreview} />
    )
  }
  if (answers) {
    return (
      <Answers trivia={trivia} setAns={setAnswers}/>
    )
  }
  if (ranking) {
    return (
      <Ranking setRanking={setRanking} trivia={trivia} />
    )
  } else {
    return (
      <div className="ManageTriviaContainer">
        {trivia ?
          <React.Fragment>
            <header className='ManageTriviaHeader'>
              {admin ?
                <img src={`${API}/register-admin/logo/${admin._id}.png`} alt="Logo" onError={(e) => e.target.src = logo} />
                :
                <img src={logo} alt="Logo" />
              }
            </header>
            <main className='ManageTriviaContent purpleColor'>
              <div className='ManageTriviaContentHeader'>
                <h1 className='ManageTriviaName purpleColor'>{trivia.projectCode}</h1>
                <p className='ManageTriviaPin'>PIN: {trivia.code}</p>
              </div>
              <div className='ManageTriviaPanel'>
                <div className='ManageTriviaOption'>
                  <h2>PREGUNTAS</h2>
                  <div className='ManageTriviaQuestions'>
                    {trivia && trivia.question ? trivia.question.map((question, i) => (
                      <div key={question.text + i} className='ManageTriviaQuestion'>
                        <h3>{question.text && question.text !== '' ? question.text : 'PREGUNTA ' + (i + 1)}</h3>
                        <div className='ManageTriviaQuestionButtons'>
                          <img src={eye} alt="ver" onClick={() => { setCurrentQuestion(i); setPreview(true) }} />
                        </div>
                      </div>
                    )) : ''}
                  </div>
                  <div className='ManageTriviaQuestion'>
                    <div className="showContent">
                      <h2>RANKING</h2>
                      <div className='ManageTriviaQuestionButtons'>
                        <img src={eye} alt="ver" onClick={() => setRanking(true)} />
                      </div>
                    </div>
                    <div className="showContent">
                      <h2>RESPUESTAS</h2>
                      <div className='ManageTriviaQuestionButtons'>
                        <img src={eye} alt="ver" onClick={() => setAnswers(true)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='ManageTriviaOption'>
                  <h2>{Object.values(userList).length === 1 ? `( ${Object.values(userList).length} ) USUARIO CONECTADO` : `( ${Object.values(userList).length} ) USUARIOS CONECTADOS`}</h2>
                  <div className='ManageTriviaConnected'>
                    {
                      Object.values(userList).map((user, idx) => {
                        return (
                          <p key={idx}> - {user.name}</p>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              <div className='ManageTriviaButtons'>
                <button className='purpleColor semiBold whiteBackground' onClick={() => props.history.push('/trivia/dashboard/available')}>VOLVER</button>
                <button className='purpleColor semiBold whiteBackground' onClick={duplicate}>CREAR COPIA</button>
                {trivia && trivia.state !== 'finished' ?
                  <button className='purpleColor semiBold whiteBackground' onClick={changeState}>{trivia.state === 'started' ? 'FINALIZAR' : 'COMENZAR'}</button>
                  : ''}
                {trivia && ((trivia.state !== 'started' && trivia.state !== 'finished') || !trivia.state) ?
                  <button className='purpleColor semiBold whiteBackground' onClick={() => props.history.push('/trivia/dashboard/triviaedit/' + trivia.code)}>EDITAR</button>
                  : ''}
                {trivia && (trivia.state === 'finished' || !trivia.state) ?
                  <button className='purpleColor semiBold whiteBackground' onClick={() => deleteTrivia(trivia._id)}>BORRAR</button>
                  : ''}
              </div>

            </main>
            <footer className='ManageTriviaFooter'>
              <img src={logo} alt="Logo Ozel Producciones" />
            </footer>
          </React.Fragment>
          : ''}
      </div>
    )
  }

}


export default withRouter(ManageTrivia)
