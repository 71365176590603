import React, { useState } from 'react'
import axios from 'axios'
import logo from '../../../media/LogoOzel.png'
import full from '../../../media/fullstar.png'
import empty from '../../../media/emptystar.png'
import { API } from '../../../config'

import './feedBack.css'

const FeedBack = ({ user, trivia, setState }) => {
  const [themes, setThemes] = useState(0)
  const [level, setLevel] = useState(0)

  const vote = async () => {
    const options = {
      data: { user: user._id, trivia: trivia._id, themes: themes, level: level },
      method: 'POST',
      url: API + "/feedback/",
      headers: { "Content-Type": "application/json" }
    }
    try {
      await axios.request(options)
      setState(3)
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className='FinishContainer linearGradientBackgound1'>
      <div className='FinishContent'>
        <h3 className='whiteColor'>CUENTANOS TU EXPERIENCIA</h3>
        <p className='whiteColor'>Temas de trivia</p><br />
        <div className='FinishVote'>
          <img src={themes >= 1 ? full : empty} alt="" onClick={() => setThemes(1)} />
          <img src={themes >= 2 ? full : empty} alt="" onClick={() => setThemes(2)} />
          <img src={themes >= 3 ? full : empty} alt="" onClick={() => setThemes(3)} />
          <img src={themes >= 4 ? full : empty} alt="" onClick={() => setThemes(4)} />
          <img src={themes >= 5 ? full : empty} alt="" onClick={() => setThemes(5)} />
        </div>
        <p className='whiteColor'>Facilidad para jugar</p><br />
        <div className='FinishVote'>
          <img src={level >= 1 ? full : empty} alt="" onClick={() => setLevel(1)} />
          <img src={level >= 2 ? full : empty} alt="" onClick={() => setLevel(2)} />
          <img src={level >= 3 ? full : empty} alt="" onClick={() => setLevel(3)} />
          <img src={level >= 4 ? full : empty} alt="" onClick={() => setLevel(4)} />
          <img src={level >= 5 ? full : empty} alt="" onClick={() => setLevel(5)} />
        </div>
        {themes > 0 && level > 0 ?
          <button className='FinishButton semiBold' onClick={vote}>GUARDAR Y CONTINUAR</button>
          : ''}
      </div>
      <img src={logo} alt="" className='FinishImgFooter' />
    </div>
  )

}

export default FeedBack
