import { useEffect, useState } from "react"
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie';
import axios from 'axios'
import { API } from '../../config'
import logo from '../../media/LogoOzel.png'
import './login.css'
import popUp from '../../components/popUp'


const cookies = new Cookies();

const submitLogin = async (e, loginForm, history) => {
  if ((e && e.key !== 'Enter') && (e && e.type !== 'click')) return
  if (loginForm.email.trim() === '' || loginForm.password.trim() === '') {
    popUp('Faltan campos por llenar', 'warning')
    return
  }
  const options = {
    data: { user: loginForm.email.trim().toLowerCase(), password: loginForm.password },
    method: 'POST',
    url: API + "/auth/login/",
    headers: { "Content-Type": "application/json" }
  }
  try {
    const res = await axios.request(options)
    cookies.set('admin', res.data.data.user, {path:'/'})
    popUp('Ingreso exitoso', true, false, 5000)
    history.push('/trivia/dashboard')
  } catch (error) {
    popUp('Usuario o contraseña incorrectos', false)
    console.error(error);
  }
}


let Login = ({ history }) => {
  const [loginForm, setLoginForm] = useState({ email: '', password: '' })

  useEffect(() => {/* 
    if (cookies.get('jwt')) {
      console.log(cookies.get('jwt'));
    } else {
      console.log('No cookies');
    } */
    if (cookies.get('admin') && cookies.get('admin') !== 'null') {
      console.log(cookies.get('admin'));
      history.push('/trivia/dashboard/')
    }
  }, [history])


  useEffect(() => {
    const aux = (e) => submitLogin(e, loginForm, history)
    window.addEventListener('keyup', aux, true)
    return () => {
      window.removeEventListener('keyup', aux, true)
    }
  }, [loginForm, history])

  const onchangeHandler = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value })
  }

  return (
    <div className="LoginContainer linearGradientBackgound1">
      <header className='LoginHeader'>
        <img src={logo} alt='Logo de Ozel' onClick={() => history.push('/')}/>
      </header>
      <div className="LoginContent">
        <form className='LoginFormContainer'>
          <div className='LoginForm whiteBackground purpleColor'>
            <div className='LoginFormInput'>
              <label htmlFor='email'>Usuario</label>
              <input type="email" id='name' className='purpleColor' name='email' value={loginForm.user} onChange={onchangeHandler} />
            </div>
            <div className='LoginFormInput'>
              <label htmlFor='password'>Contraseña</label>
              <input type="password" id='password' className='purpleColor' name='password' value={loginForm.password} onChange={onchangeHandler} />
            </div>
          </div>
          <a href="erda" className='LoginLink'>¿Olvidaste tu contraseña?</a>
        </form>
        <div className='LoginOptionsContainer'>
          <button className='LoginOptionButton whiteBackground purpleColor semiBold' onClick={(e) => submitLogin(e, loginForm, history)}>INGRESAR</button>
          <button className='LoginOptionButton whiteBackground purpleColor semiBold' onClick={() => history.push('/trivia/register')}>REGISTRARSE PARA CREAR TRIVIAS</button>
        </div>
        <img src={logo} alt="" className='LoginFooterImg' />
      </div>
    </div>
  )
}

export default withRouter(Login)
